<template>
  <div>

    
    <div class="fnt">
      <el-row>
         <el-col  class="hidden-xs-only" :span="3" > <span>heherongzi.com </span></el-col>
         <el-col :span="21"> <span>上海记算企业服务有限公司</span></el-col>
      </el-row>
              
      </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .fnt{
        font-family: 'Courier New', Courier, monospace;
        font-size: 20px;
    }
</style>