<template>
  <div>

    <el-carousel :height="carouselHeight"  indicator-position="none" :interval="5000" >
        <el-carousel-item v-for="url in urls" :key="url">
        <el-image :src="url" fit="fill"></el-image>
        </el-carousel-item>
    </el-carousel>
    </div>
</template>

<script>
export default {
    data(){
        return {            
            urls:[require('@/assets/top1.jpg'),require('@/assets/top2.jpg')],
            carouselHeight:"303px"
        }
    },
    mounted(){
      //this.carouselHeight = document.documentElement.clientWidth+"px";
      let w = document.documentElement.clientWidth;
      let h = w>600?"303px":"110px";
      this.carouselHeight = h;
      //console.log("宽度", this.carouselHeight);
    }
}
</script>

<style scoped>
 .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>