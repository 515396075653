<template>
  <div>

    <el-row >
        <el-col :span="24"><div class="kxTitle1">企业价值观</div></el-col>    
    </el-row>
     <el-row >
        <el-col :span="24"><div class="kxTitle2">“以客户为中心，以奋斗者为本，坚持长期艰
苦奋斗，持续自我批判”。坚持以客户为中心，客户满意为宗旨，实现 “服务
组织，助力组织”的目标，弘扬“专业领先、诚信服务、创造价值、回馈客户”
的精神，坚持“专业创造价值”的品牌定位，在“企业综合服务+”和“互联网+ ”的新时代，实现“专业，让经营更简单”的客户体验。</div></el-col>        
    </el-row>
    <el-row >
        <el-col :span="8" :xs="24">
            <div class="kxTitle3">
                <el-image :src="require('@/assets/kx1.jpg')" fit="fill"></el-image>
                <h4>记算财税业务</h4>               
            </div>
            <div class="div3">
                 记算集团的服务范围主要为代理记账、税收筹划及政府返税
等。本集团致力于通过专业的服务及合理的解决方案，帮助企业通过税收优惠、
税收筹划等合理合法的避税手段以达到帮助企业降低经营成本、提高企业财务管
理水平、最终提升企业市场竞争力为目的
            </div>
        </el-col>        
        <el-col :span="8" :xs="24">
            <div class="kxTitle3"><el-image :src="require('@/assets/kx2.jpg')" fit="fill"></el-image>
             <h4>记算证照业务</h4>
            </div>
           <div class="div3">
                 对企业提供办理执照、行政审批、高新技术企业认
定、双软认证、商标代理及变更登记等服务。本集团旨在降低节省企业在工商证
照类业务中花费的时间成本，帮助企业将资源更好的投入在自身的主营业务中，
实现企业规模的快速增长
            </div>
        </el-col>        
        <el-col :span="8" :xs="24">
            <div class="kxTitle3"><el-image :src="require('@/assets/kx3.jpg')" fit="fill"></el-image>
            <h4>记算法律业务</h4>
            </div>
            <div class="div3">
                通过资深法律服务团队提供专业服务，主要面向各
类大、中、小、微型企业，协助企业在设立、运营、投资、融资、监督、审核、
防控等方面，提供交流平台、进行专业指导、专项法律事务处理等各项法律服务
。我们将根据不同企业用户的个性化需求，提供一站式专业性法律支持和服务
            </div>
        </el-col>        
    </el-row>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .kxTitle1{
        line-height: 60px;
        font-size: 28px;
        font-weight: 300;
    }
    .kxTitle2{
        line-height: 60px;
        font-size: 20px;
        font-weight: 300;
    }
    .kxTitle3{
        line-height: 30px;
        font-size: 20px;
        font-weight: 300;
    }
    .div3{
        padding: 10px 10px;

    }
</style>